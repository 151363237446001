<template>
  <stripe-loader class="card-form">
    <div v-if="intentLoaded">
      <!-- Discount Banner -->
      <div class="card-form__banner text-center text-sm bg-orange-100 rounded-t-lg mx-30" v-if="showForm && bannerText !== null">
        {{ bannerText }}
      </div>
      <div class="content-center mx-10 pb-6" v-if="showForm">
        <!-- bold black title -->
        <div class="text-center text-xl text-brand-black font-bold mx-30">
          {{ title }}
        </div>
        <!-- top text brown brand -->
        <div class="text-center text-sm font-medium mt-2 mx-30 text-brand-brown">
          {{ topText }}
        </div>
      </div>
      <div class="card-form__inner justify-content content-center flex flex-wrap mx-10" v-if="showForm">
        <!-- STRIPE ELEMENTS -->
        <div class="payment-simple w-full">
          <StripeElements :stripe-key="pk_key" :instance-options="instanceOptions" :elements-options="elementsOptions" #default="{ elements }" ref="elms">
            <StripeElement type="payment" :elements="elements" @ready="isLoaded" ref="payment" class="md:w-11/12 md:mx-auto" />
          </StripeElements>
        </div>
        <button v-if="elementsLoaded" :class="['card-form__button', 'main-cta', { disabled: isUpdating }]" @click="updateCard" :disabled="isUpdating">
          <span v-if="isUpdating"><Loader /> Updating...</span>
          <span v-else>{{ updateButtonLabel }}</span>
        </button>
      </div>
      <!-- Update Failed Message -->
      <div class="card-form__inner payment-message text-center" v-if="cardUpdateFailed">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8">{{ message.header }}</p>
          <p class="text-lg text-brand-black mt-4">{{ message.description }}</p>
        </div>
        <button class="card-form__button mt-15 main-cta" @click="retry" type="button">Try Again</button>
      </div>
      <!-- Update Success Message -->
      <div class="card-form__inner payment-message text-center" v-if="cardUpdateSuccess">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8">{{ message.header }}</p>
          <p class="text-lg text-brand-black mt-4">{{ message.description }}</p>
        </div>
        <button class="card-form__button mt-15 main-cta" @click="redirect" type="button">Continue to {{ companyName }}</button>
      </div>
      <!-- Invoice is Already Uncollectible -->
      <!-- <div class="card-form__inner payment-message text-center" v-if="alreadyUncollectible && !cardUpdateComplete">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8 pt-10">Your invoice has been marked uncollectible, and can no longer be paid.</p>
        </div>
        <button class="card-form__button mt-15 main-cta" @click="redirect" type="button">Continue to {{ companyName }}</button>
      </div> -->
    </div>
  </stripe-loader>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus';
import StripeLoader from '@/components/StripeLoader.vue';
import Loader from '@/components/ButtonLoader.vue';
// Note: ReactivationStripeForm has no watermark because for Reactivations the stripe form is hidden upon success.

import appearance from '@/helpers/elements-config';
import * as Filters from '@/helpers/filters';
import EventBus from '@/helpers/event-bus';
import OrgService from '@/api/org.service';
import router from '@/router';

export default {
  name: 'ReactivationStripeForm',
  components: {
    StripeElements,
    StripeElement,
    StripeLoader,
    Loader,
  },
  computed: {
    updateButtonLabel() {
      if (this.$store.state.account.ctaText !== undefined && this.$store.state.account.ctaText !== '') {
        return this.$store.state.account.ctaText;
      }
      const offerCount = this.offers.length;
      if (offerCount === 0) return 'Save Card';
      if (offerCount === 1) return 'Save Card & Redeem Offer';
      return 'Save Card & Redeem Offers';
    },
    account() {
      return this.$store.state?.account;
    },
    discount() {
      const discount = this.account?.dunning?.displayDiscount;
      if (!discount) return null;
      const { coupon, ...theRest } = discount;
      const currency = this.currency; // eslint-disable-line
      const updatedDiscount = {
        coupon: {
          ...coupon,
          currency,
        },
        ...theRest,
      };
      return updatedDiscount;
    },
    amountBeforeDiscount() {
      return this.discount?.amountBeforeDiscount;
    },
    amountAfterDiscount() {
      return this.discount?.amountAfterDiscount;
    },
    hasDiscount() {
      return !!this.discount;
    },
    bannerText() {
      return null;
    },
    title() {
      return "You're almost there...";
    },
    topText() {
      const discountAction = "Just update it below and you'll lock in your special offer.";
      const regularAction = "Just update it below and you'll be all set.";
      const action = this.hasDiscount === true ? discountAction : regularAction;
      let reason = 'The card we have for you is not valid.';
      if (this.cardExpired) {
        reason = 'The card we have for you has expired.';
      } else if (this.paymentFailed) {
        reason = 'The card we have for you was declined.';
      }
      return `${reason} ${action}`;
    },
    paymentFailed() {
      return false;
    },
    cardExpired() {
      return false;
    },
    subscription() {
      return this.$store.state.account.subscription;
    },
    alreadySubscribed() {
      return this.subscription?.status === 'paid';
    },
    showForm() {
      return !this.cardUpdateFailed && !this.cardUpdateComplete && !this.alreadySubscribed;
    },
    cardUpdateSuccess() {
      return this.cardUpdateComplete && !this.cardUpdateFailed;
    },
    companyName() {
      return this.$store.state.account.orgName;
    },
    offers() {
      return this.$store.state.account.offers;
    },
    redirectLink() {
      let link = this.$store.state.account?.dunning?.checkoutPage?.redirectLink;
      // prepend https:// if not already there
      if (link && link.indexOf('://') === -1) {
        link = `https://${link}`;
      }
      return link;
    },
  },
  data() {
    return {
      stripeLoaded: false,
      intentLoaded: false,
      elementsLoaded: false,
      cardUpdateComplete: false,
      cardUpdateFailed: false,
      isUpdating: false,
      message: {
        header: '',
        description: '',
      },
      pk_key: process.env.VUE_APP_STRIPE_KEY,
      instanceOptions: { locale: 'auto', stripeAccount: null },
      elementsOptions: { locale: 'auto', loader: 'auto', appearance },
    };
  },

  mounted() {
    // Load Data for Card Form
    this.instanceOptions.stripeAccount = this.$store.state.account.stripeAccountId;
    this.getSetupIntent();
  },

  methods: {
    async retry() {
      // Adding the component back in
      this.cardUpdateFailed = false;

      // Set up again:
      this.intentLoaded = false;
      await this.getSetupIntent();
    },
    redirect() {
      // navigate to the org's website
      window.location.href = this.redirectLink;
    },
    isLoaded() {
      this.elementsLoaded = true;
    },

    // Configures Stripe by setting up the elements and creating the card element.
    async getSetupIntent() {
      try {
        const data = await OrgService.getSetupIntent();
        this.elementsOptions.clientSecret = data.client_secret;
        this.intentLoaded = true;
      } catch (err) {
        console.log('Error on getSetupIntent:', err.response?.data);
        this.$store.commit('SET_ERROR', { errorMessage: err.response?.data });
        // router.push('/error');
      }
    },

    async updateCard() {
      if (this.isUpdating) return; // Disable button while loading!
      this.isUpdating = true;
      const { elements, instance } = this.$refs.elms;

      /*
      const previewMode = true;
      if (previewMode) {
        console.log('PREVIEW MODE: Not updating card');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.cardUpdateComplete = true;
        this.message.header = 'You\'re all good.';
        this.message.description = 'Your card has been updated and your subscription has been reactivated.';
        this.isUpdating = false;
        EventBus.$emit('reactivated', { success: true });
        return;
      }
      */

      try {
        const response = await instance.confirmSetup({ elements, redirect: 'if_required' });
        console.log('response', response);
        if (response.error) {
          // Inform the customer that there was an error.
          this.cardUpdateFailed = true;
          this.message.header = response.error.message || 'There was an error processing your payment.';
          this.message.description = 'Please double check your payment method and try again.';
        }
        if (response.setupIntent && response.setupIntent.status === 'succeeded') {
          // Set new payment method as default and charge for outstanding invoice amount
          let reactivationOutcome;
          try {
            reactivationOutcome = await OrgService.reactivate({ paymentMethodId: response.setupIntent.payment_method });
            if (!reactivationOutcome?.success) {
              // console.log('Error:', reactivationOutcome);
              throw new Error('There was an error while reactivating your subscription.');
            }
            this.cardUpdateComplete = true;
            this.message.header = "You're all good.";
            this.message.description = 'Your card has been updated and your subscription has been reactivated.';
            EventBus.$emit('reactivated', reactivationOutcome);
          } catch (error) {
            EventBus.$emit('logIssue', this.$store.state.account);
            console.log('Error updating card and paying invoice', error);
            this.cardUpdateFailed = true;
            this.message.header = response.error?.message || error.message || 'There was an error processing your payment.';
            // reactivationOutcome?.message // raw error message from API
            this.message.description = 'Please double check your payment method and try again.';
          }
        }
      } catch (error) {
        EventBus.$emit('logIssue', this.$store.state.account);
        console.log('error', error);
      } finally {
        this.isUpdating = false;
      }
    },
  },
  filters: {
    formatCouponDuration: Filters.formatCouponDuration,
  },
};
</script>
<style scoped>
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .card-form__inner {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .card-form__button {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
.card-form__inner {
  padding-top: 40px;
  min-height: 350px;
}

.payment-message {
  padding-top: 40px;
  min-height: 300px;
}

@media only screen and (max-width: 480px) {
  .card-form__inner {
    padding-top: 0px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-height: 350px;
  }
}
</style>
